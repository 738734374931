import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import HowtoDealWithCrowdfunding from 'images/blog-images/how-to-deal-with-crowdfunding-fulfillment.png';
import CrowdFundingFulfillment from 'images/blog-images/crowdfunding-fulfillment.png';
import CrowdFundingFulfillmentFeatured from 'images/featured-image/crowdfunding-fulfillment-featured.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query CrowdFundingFulfillment {
      file(relativePath: { eq: "crowdfunding-fulfillment.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="crowdfunding fulfillment & shipping solutions"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Crowdfunding Fulfillment & Shipping Solutions | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-crowdfunding-fulfillment" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Crowdfunding Fulfillment & Shipping Solutions | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-crowdfunding-fulfillment" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: CrowdFundingFulfillmentFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="crowdfunding-fulfillment-and-shipping-solutions"
        title="Crowdfunding Fulfillment & Shipping Solutions"
        date="2021-01-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
          	<h5 className="no-top">
          		<strong>
          			What is Crowdfunding Fulfillment?
          		</strong>
          	</h5>
            <p>
              Basically, crowdfunding fulfillment is the entire process from the storage of your backer rewards in warehouses owned by your fulfillment partner, to the delivery at your customers' doorstep, for crowdfunding campaigns. Wherever in the world these backers maybe, your crowdfunding fulfillment partner provides you with a couple of shipping options, couriers and prices to efficiently and successfully deliver your goods worldwide. Shipkoo’s crowdfunding fulfillment solution helps you to make the entire process fast and easy. Start delivering results today with <a href="https://shipkoo.com/">Shipkoo</a>.
            </p>

            <Image src={CrowdFundingFulfillment} className="" alt="crowdfunding fulfillment & shipping solutions"/>

            <h5>
              <strong>
                How to Deal with Crowdfunding Fulfillment Effectively?
              </strong>
            </h5>

            <p>
            	<strong>
            		Packaging
            	</strong><br />
            	It is important to negotiate with your manufacturers to utilize lightweight material to make the products. The art of packaging could not only effectively save space, thus reducing shipping costs, but also secure the shipment all the way. Elaborated packaging helps you to save a lot of operating costs. For example, if you take 10 cm off the outer box packaging, reducing the “length+width+height” from 100cm to 90cm. It saved around $15 per package. With 1,000 orders to be shipped, this is gonna save $15,000 in total.
            </p>

            <p>
              	<strong>
            		Estimation of shipping destinations
            	</strong><br />
            	Shipping can become complicated when your backers are located around the world. Shipping fees highly depend on the kind of product shipped, weight and dimensions, whether or not it includes batteries or liquids, the countries you want to ship it to and the shipping method you choose to go with. The delivery time differs in regions. Make research on similar projects to estimate the regional distribution of the backers and based on the statistics, calculate the shipping costs and shipping time you need to get all the backers to receive the rewards at the same time. Extra fees and time would apply when shipping to remote areas. If the shipping costs eat up your most profit, you could decide whether it is necessary to have limits on the receiving destination.
            </p>

            <p>
              	<strong>
            		The choice of a suitable fulfillment partner
            	</strong><br />
            	Shipping to thousands of backers worldwide requires a logistics partner with facilities worldwide and experience to match your needs. Cooperation with logistics management companies could be time-saving, they could handle storage, packaging, and shipping for you. Most fulfillment providers do not have dedicated features within their dashboard designed to manage crowdfunding fulfillment. Shipkoo has dedicated features meant precisely for managing your crowdfunding fulfillment. Our goal is to completely release you of the burden of figuring out how to get your products to your backers. Simply make sure we have all the order information and let us know where to pick up your products, and we handle the rest.
            </p>

            <Image src={HowtoDealWithCrowdfunding} alt="how to deal with crowdfunding fulfillment effectively?" />

            <h5>
              <strong>
                How it works
              </strong>
            </h5>

            <OrderedList>
            	<li>
            		<p><strong>Get in touch</strong></p>
            		First of all, we would need some of your information in order for us to provide you with a customized shipping and fulfillment solution to fulfill your crowdfunding orders. We generally require a link to your crowdfunding campaign, your contact information and some details regarding the products you want to be shipped.
            	</li>
            	<li>
            		<p><strong>Order exchange</strong></p>
            		Based on your complete order list we will determine the fulfillment and shipping costs. Our shipping specialist will contact you and talk over your business challenges, providing you with suggestions and a quote.
            	</li>
            	<li>
            		<p><strong>Your dedicated account manager</strong></p>
            		You will get assigned a dedicated account manager who will walk you through the Shipkoo account setup - which is simple and straightforward. Our cutting-edge order management platform enables you to import your orders easily and get full visibility on the status of your orders.
            	</li>
            </OrderedList>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;